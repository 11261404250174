<template>
  <layout>
    <template slot="content">
      <div class="row justify-content-between mb-4 no-gutters">
        <div class="col-auto d-md-none">
          <h3 class="font-weight-bold">{{ payload.name ? payload.name : '' }}</h3>
        </div>
        <div class="col-auto d-none d-md-inline-block">
          <router-link :to="{ name:'records' }" class="btn btn-outline-dark btn-sm">Todos los expedientes</router-link>
        </div>
        <div class="col-auto ml-auto">
          <div class="dropdown">
            <button class="btn btn-gradient-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Acciones
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a @click.prevent="RECORD_TOGGLE_MODAL_UPDATE" class="dropdown-item" href="#">Editar Expediente</a>
              <a @click.prevent="TOGGLE_CREATE_AGENDA_TASK_MODAL(true)" class="dropdown-item" href="#">Agregar Tarea</a>
              <a @click.prevent="TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL(true)" class="dropdown-item" href="#">Agregar Audiencia</a>
              <a @click.prevent="ATTACHMENT_TOGGLE_MODAL_CREATE" class="dropdown-item" href="#">Agregar Adjunto</a>
              <a @click.prevent="INVOICE_TOGGLE_MODAL_CREATE" class="dropdown-item d-none d-md-inline-block" href="#">Crear Factura</a>
              <a @click.prevent="closeInstanceModal= true" class="dropdown-item d-none d-md-inline-block" href="#">Actualizar Estado</a>
              <a
                @click.prevent="deleteRecord"
                class="dropdown-item text-danger d-none d-md-inline-block"
                href="#"
              >
                Borrar Expediente
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start my -3">
        <div class="col-12">
          <h4>Expediente: #{{payload.number}}</h4>
        </div>
      </div>
      <div class="row tms-record">
        <div class="col-12 col-md-6 grid-margin">
          <div class="card tms-record__card-body tms-record__card-body--clients">
            <div class="card-body">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Cliente</span>
                </div>
                <div v-if="payload.client" class="text-left col-12">
                  <span v-if="payload.client.type_id === 2">
                    {{ payload.client.commercial_name }}
                  </span>
                  <span v-else>
                    {{ payload.client.first_name + ' ' + payload.client.last_name }}
                  </span>
                </div>
              </div>
              <hr class="mt-2 mb-2">
              <div class="row">
                <div class="my-1 text-small  text-left col-12 font-weight-bold">
                  <span>Abogado</span>
                </div>
                <div class="text-left col-12">
                  <span v-if="payload.lawyer" :title="payload.lawyer.first_name + ' ' + payload.lawyer.last_name">
                    {{ payload.lawyer.first_name }} {{ payload.lawyer.last_name }}
                  </span>
                  <span v-else>No asignado</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 grid-margin">
          <div class="card tms-record__card-body tms-record__card-body--opposite">
            <div class="card-body">
              <div class="row mb-2">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Parte Contraria</span>
                </div>
                <div class="text-left col-12">
                  <span v-if="payload.against_to">
                    {{ payload.against_to }}
                  </span>
                  <span v-else>
                    No asignado
                  </span>
                </div>
              </div>
              <hr class="mt-2 mb-2">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Abogado</span>
                </div>
                <div class="text-left col-12">
                  <span v-if="payload.defense_lawyer">
                    {{ payload.defense_lawyer }}
                  </span>
                  <span v-else>
                    No asignado
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card">
            <div class="card-body border-left">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Nombre del caso</span>
                </div>
                <div class="text-left col-12">
                  <span>{{ payload.name ? payload.name : '' }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 grid-margin">
          <div class="card">
            <div class="card-body border-left">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Próxima Audiencia</span>
                </div>
                <div class="text-left col-12">
                  <span>{{ _.get(_.last(_.filter(payload.events, function(n) { return n.status === 1 && n.type === 3 })), 'date')  ? _.get(_.last(_.filter(payload.events, function(n) { return n.status === 1 && n.type === 3 })), 'date') : 'Ninguna' | moment('DD-MM-YYYY') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 grid-margin">
          <div class="card">
            <div class="card-body border-left">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Número</span>
                </div>
                <div class="text-left col-12">
                  <span v-if="payload.number">{{payload.number}}</span><span v-else>No asignado</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 grid-margin">
          <div class="card">
            <div class="card-body border-left">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Instancia</span>
                </div>
                <div class="text-left col-12">
                  <span>{{ payload.instance ? payload.instance.name : 'No disponible' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 grid-margin">
          <div class="card">
            <div class="card-body border-left">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Tribunal</span>
                </div>
                <div class="text-left col-12">
                  <span>{{payload.court ? payload.court.name : 'No disponible'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 grid-margin">
          <div class="card">
            <div class="card-body border-left">
              <div class="row">
                <div class="my-1 text-small text-left col-12 font-weight-bold">
                  <span>Status</span>
                </div>
                <div class="text-left col-12">
                  <span v-if="payload.status == 2" >Cerrado</span>
                  <span v-else-if="payload.status == 3" >Esperando Sentencia</span>
                  <span v-else-if="payload.status == 4" >Fijar Audiencia</span>
                  <span v-else-if="payload.status == 5" >Conociendose</span>
                  <span v-else>Abierto</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-none d-md-inline-block">
                  <b-tabs content-class="mt-1" fill>
                    <b-tab title="Timeline" active @click="resetTables">
                      <timeline :logs="payload.logs"></timeline>
                    </b-tab>
                    <b-tab title="Agenda" active @click="resetTables">
                      <div class="row align-items-center mt-4">
                        <div class="col-auto show-filters" v-if="!displayAgendaFilters">
                          <a @click.prevent="displayAgendaFilters=1" href="#"><i class="mdi mdi-filter-variant"></i></a>
                        </div>
                        <div class="col-12 col-md-4 mb-3 mb-md-0" v-if="displayAgendaFilters">
                          <div class="form-group mb-0">
                            <label for="date_filter">Fecha de Vencimiento</label>
                            <select v-model="agendaFilters.date_filter" name="date_filter" id="date_filter" class="form-control">
                              <option :value="0" disabled>Seleccionar</option>
                              <option :value="1">Hoy</option>
                              <option :value="2">Mañana</option>
                              <option :value="3">Ésta   Semana</option>
                              <option :value="4">Próxima Semana</option>
                              <option :value="5">Personalizado</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3 mb-md-0" v-if="agendaFilters.date_filter === 5" >
                          <div class="form-group mb-0">
                            <label for="date">Fecha de Compromiso</label>
                            <datetime v-model="agendaFilters.date_custom" :config="dateConfig" id="date" class="form-control"></datetime>
                          </div>
                        </div>
                        <div class="col-12 col-md-auto mt-2 mt-3 mb-3 mb-md-0" v-if="displayAgendaFilters" >
                          <button class="btn btn-sm btn-block btn-outline-secondary" type="button" @click.prevent="resetFilter"><i class="mdi mdi-close"></i></button>
                        </div>
                      </div>
                      <div class="row align-items-center mt-2">
                        <div class="ml-auto col-auto">
                          <div class="dropdown">
                            <button class="btn btn-gradient-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="mdi mdi-plus"></i> Nuevo
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                              <a @click.prevent="TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL(true)" class="dropdown-item" href="#">Agregar Cita</a>
                              <a @click.prevent="TOGGLE_CREATE_AGENDA_TASK_MODAL(true)" class="dropdown-item" href="#">Agregar Tarea</a>
                              <a @click.prevent="TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL(true)" class="dropdown-item" href="#">Agregar Audiencia</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2 tms-event-type">
                        <div class="col-12">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <button
                              type="button"
                              :class="agendaFilters.type === 4 ? 'btn-gradient-info' : 'btn-secondary'"
                              @click.prevent="agendaFilters.type = 4"
                              class="btn btn-sm"
                            >
                              Todo
                            </button>
                            <button
                              type="button"
                              :class="agendaFilters.type === 3 ? 'btn-gradient-info' : 'btn-secondary'"
                              @click.prevent="agendaFilters.type = 3"
                              class="btn btn-sm tms-event-type__audience"
                            >
                              Audiencias
                            </button>
                            <button
                              type="button"
                              :class="agendaFilters.type === 1 ? 'btn-gradient-info' : 'btn-secondary'"
                              @click.prevent="agendaFilters.type = 1"
                              class="btn btn-sm tms-event-type__appointment"
                            >
                              Citas
                            </button>
                            <button
                              type="button"
                              :class="agendaFilters.type === 2 ? 'btn-gradient-info' : 'btn-secondary'"
                              @click.prevent="agendaFilters.type = 2"
                              class="btn btn-sm tms-event-type__task"
                            >
                              Tareas
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="payload.id">
                          <datatable
                            :api="'api/agenda?record_id=' + payload.id"
                            :fields="agendaFields"
                            :otherParams="agendaFilters"
                            label="agenda"
                            :hide-search="true"
                          />
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Adjuntos" @click="resetTables">
                      <div class="row align-items-center mt-4 mb-2">
                        <div class="col-auto ml-auto">
                          <button
                            @click.prevent="ATTACHMENT_TOGGLE_MODAL_CREATE"
                            class="btn btn-sm btn-outline-info"
                          >
                            Subir Adjunto
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="payload.id">
                          <datatable :api="'api/attachments?record_id=' + payload.id" :fields="attachmentsFields"></datatable>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Gastos" @click="resetTables">
                      <div class="row align-items-center mt-4 mb-2">
                        <div class="col-auto ml-auto">
                          <button @click.prevent="EXPENSE_TOGGLE_MODAL_CREATE" class="btn btn-sm btn-outline-info">Agregar</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="payload.id">
                          <datatable :api="'api/expenses?record_id=' + payload.id" :fields="expenseFields" label="expense"></datatable>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Información Adicional" @click="resetTables">
                      <div class="row mt-4">
                        <div class="col-12">
                          <div class="form-group">
                            <label for="notes">Notas</label>
                            <textarea v-model="payload.notes" id="notes" cols="15" rows="5" class="form-control"></textarea>
                            <div class="d-flex justify-content-end">
                              <button type="button" @click.prevent="updateRecord" class="btn btn-info btn-sm mt-3 ml-auto">Guardar</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="case_details">Detalles Del Caso</label>
                            <textarea v-model="payload.case_details" id="case_details" cols="15" rows="5" class="form-control"></textarea>
                            <div class="d-flex justify-content-end">
                              <button type="button" @click.prevent="updateRecord" class="btn btn-info btn-sm mt-3 ml-auto">Guardar</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <label for="demand_cause">Causa de la Demanda</label>
                            <textarea v-model="payload.demand_cause" id="demand_cause" cols="15" rows="5" class="form-control"></textarea>
                            <div class="d-flex justify-content-end">
                              <button type="button" @click.prevent="updateRecord" class="btn btn-info btn-sm mt-3 ml-auto">Guardar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
                <div class="col-12 d-md-none">
                  <b-tabs content-class="mt-1" fill>
                    <b-tab title="Agenda" active @click="resetTables">
                      <div class="row align-items-center mb-2 mt-4">
                        <div class="col-12">
                          <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" :class="agendaFilters.type === 4 ? 'btn-warning' : 'btn-secondary'" @click.prevent="agendaFilters.type = 4" class="btn btn-sm">Todo</button>
                            <button type="button" :class="agendaFilters.type === 3 ? 'btn-danger' : 'btn-secondary'" @click.prevent="agendaFilters.type = 3" class="btn btn-sm">Audiencias</button>
                            <button type="button" :class="agendaFilters.type === 1 ? 'btn-info' : 'btn-secondary'" @click.prevent="agendaFilters.type = 1" class="btn btn-sm">Citas</button>
                            <button type="button" :class="agendaFilters.type === 2 ? 'btn-success' : 'btn-secondary'" @click.prevent="agendaFilters.type = 2" class="btn btn-sm">Tareas</button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="payload.id">
                          <datatable :api="'api/agenda?record_id=' + payload.id" :fields="agendaFields" :otherParams="agendaFilters" label="agenda"></datatable>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Adjuntos" @click="resetTables">
                      <div class="row align-items-center mt-4 mb-2">
                        <div class="col-auto ml-auto">
                          <button @click.prevent="ATTACHMENT_TOGGLE_MODAL_CREATE" class="btn btn-sm btn-outline-info">Subir Adjunto</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="payload.id">
                          <datatable :api="'api/attachments?record_id=' + payload.id" :fields="attachmentsFields"></datatable>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="Gastos" @click="resetTables">
                      <div class="row align-items-center mt-4 mb-2">
                        <div class="col-auto ml-auto">
                          <button @click.prevent="EXPENSE_TOGGLE_MODAL_CREATE" class="btn btn-sm btn-outline-info">Agregar</button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12" v-if="payload.id">
                          <datatable :api="'api/expenses?record_id=' + payload.id" :fields="expenseFields" label="expense"></datatable>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <file :payload="payload"></file>
      <b-modal v-model="closeInstanceModal" no-fade ref="close-instance" size="xl" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
        <div class="row">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-10">
                    Actualizar Estado
                  </div>
                  <div class="col-2">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeInstanceModal = false">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
                <div class="row mt-4 justify-content-center">
                  <div class="col-12 col-md-6 col-lg-4">
                    <button @click.prevent="closeCase" class="btn btn-gradient-danger py-4 px-4 btn-block mb-4">Cerrar Caso</button>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <button @click.prevent="changeStatus" class="btn btn-gradient-success py-4 px-4 btn-block mb-4">Cambiar Estado</button>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4">
                    <button @click.prevent="changeInstance" class="btn btn-gradient-info py-4 px-4 btn-block mb-4">Cambiar Instancia</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="changeInstanceModal" no-fade ref="change-instance" size="xl" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
        <div class="row">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-10">
                    Cambiar Instancia
                  </div>
                  <div class="col-2">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeChangeInstanceModal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
                <form class="forms-sample">
                  <div class="form-group">
                    <label for="instance_id">Instancia</label>
                    <v-select
                      v-model="payload.instance_id"
                      :options="instancesDropdown"
                      id="instance_id"
                      :reduce="r => r.code"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>
                          Empiece a escribir para buscar.
                        </em>
                      </template>
                    </v-select>
                    <div v-if="errors.hasOwnProperty('instance_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.instance_id[0]}}</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="court_id">Tribunal</label>
                    <v-select
                      v-model="payload.court_id"
                      :options="courtsDropdown"
                      id="court_id"
                      :reduce="r => r.code"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>
                          Empiece a escribir para buscar.
                        </em>
                      </template>
                    </v-select>
                    <div v-if="errors.hasOwnProperty('court_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.court_id[0]}}</small>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <button @click.prevent="updateRecord" class="btn btn-gradient-info btn-md">Actualizar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="changeStatusModal" no-fade ref="change-status" size="xl" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
        <div class="row">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-10">
                    Actualizar Estado
                  </div>
                  <div class="col-2">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeChangeStatusModal">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
                <form class="forms-sample">
                  <div class="form-group">
                    <label for="instance_id">Actualizar Estado</label>
                    <v-select
                      v-model="payload.status"
                      :options="statusDropdown"
                      id="status"
                      :reduce="r => r.code"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>
                          Empiece a escribir para buscar.
                        </em>
                      </template>
                    </v-select>
                    <div v-if="errors.hasOwnProperty('instance_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.instance_id[0]}}</small>
                    </div>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <button @click.prevent="updateRecord" class="btn btn-gradient-info btn-md">Actualizar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal v-model="attachmentDetailModal" no-fade ref="attachmentDetails" size="md" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
        <div class="row">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-10">
                    Detalles de Adjunto
                  </div>
                  <div class="col-2">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="attachmentDetailModal = false">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                </div>
                <form class="forms-sample">
                  <div class="form-group">
                    <label for="category">Categoría</label>
                    <input v-model="attachmentDetails.category" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="number">Documento Número</label>
                    <input v-model="attachmentDetails.number" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="delivered_by">Alguacíl</label>
                    <input v-model="attachmentDetails.delivered_by" type="text" class="form-control">
                  </div>
                  <div class="form-group">
                    <label for="details">Descripción</label>
                    <textarea v-model="attachmentDetails.details" class="form-control"></textarea>
                  </div>
                  <div class="row justify-content-end">
                    <div class="col-auto">
                      <button @click.prevent="attachmentDetailModal = false" class="btn btn-gradient-info btn-md">Cerrar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <expense-create :setRecord="payload.id"></expense-create>
      <invoice-create :setRecord="payload.id" :payload="payload"></invoice-create>
      <create-agenda-appointment :setRecord="payload.id" :setClient="payload.client_id"></create-agenda-appointment>
      <create-agenda-task :setRecord="payload.id" :setClient="payload.client_id"></create-agenda-task>
      <create-agenda-audience :setRecord="payload.id" :setClient="payload.client_id"></create-agenda-audience>
      <record-update v-if="payload" :payload="payload"></record-update>
      <expense-update v-if="expenseSelected" :setRecord="payload.id" :payload="expenseSelected"></expense-update>
      <edit-agenda-appointment v-if="agendaEventSelected" :payload="agendaEventSelected" :setRecord="payload.id" :setClient="payload.client_id"></edit-agenda-appointment>
      <edit-agenda-task v-if="agendaEventSelected" :payload="agendaEventSelected" :setRecord="payload.id" :setClient="payload.client_id"></edit-agenda-task>
      <edit-agenda-audience v-if="agendaEventSelected" :payload="agendaEventSelected" :setRecord="payload.id" :setClient="payload.client_id"></edit-agenda-audience>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import Timeline from '@/components/Timeline'
import InvoiceCreate from '@/components/CreateInvoice'
import ExpenseCreate from '@/components/CreateExpense'
import ExpenseUpdate from '@/components/EditExpense'
import RecordUpdate from '@/components/EditRecord'
import File from '@/components/FileUploader'
import CreateAgendaAppointment from '@/components/CreateAgendaAppointment'
import EditAgendaAppointment from '@/components/EditAgendaAppointment'
import CreateAgendaTask from '@/components/CreateAgendaTask'
import EditAgendaTask from '@/components/EditAgendaTask'
import CreateAgendaAudience from '@/components/CreateAgendaAudience'
import EditAgendaAudience from '@/components/EditAgendaAudience'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'RecordDetails',
  props: ['id'],
  data () {
    return {
      payload: [],
      errors: {},
      form: {
        instance_id: '',
        court_id: ''
      },
      agendaFilters: {
        type: 4,
        date_filter: 0,
        date_custom: ''
      },
      dateConfig: {
        altInput: true,
        enableTime: false,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        locale: Spanish // locale for this instance only,
      },
      agendaFields: [
        {
          name: 'agenda_event_type',
          title: 'Tipo de Evento'
        },
        {
          name: 'date',
          title: 'Fecha',
          formatter: (value) => {
            if (value) {
              const date = this.$moment(value)
              return date.isValid() ? date.format('DD-MM-YYYY') : 'No Disponible'
            }
          }
        },
        {
          name: 'status',
          title: 'Estado',
          formatter: (value) => {
            let status = ''
            if (value === 1) {
              status = '<span class="badge badge-info">Pendiente</span>'
            }
            if (value === 2) {
              status = '<span class="badge badge-info">Completada</span>'
            }
            if (value === 3) {
              status = '<span class="badge badge-info">Re-agendada</span>'
            }
            if (value === 4) {
              status = '<span class="badge badge-info>Cancelada</span>'
            }
            return status
          }
        },
        {
          name: 'agenda_action',
          title: 'Action'
        }
      ],
      expenseFields: [
        {
          name: 'expense',
          title: 'Fecha'
        },
        {
          name: 'amount',
          title: 'Monto'
        },
        {
          name: 'invoiced',
          title: 'Fecha de Facturación',
          formatter: value => {
            const date = this.$moment(value)
            return date.isValid() ? date.format('DD-MM-YYYY') : 'No facturado'
          },
          visible: false
        },
        {
          name: 'description',
          title: 'Descripción'
        },
        {
          name: 'invoiced',
          title: 'Estado',
          formatter: value => {
            const date = this.$moment(value)
            return date.isValid() ? '<div>Facturado</div><small>' + date.format('DD-MM-YYYY') + '</small>' : 'No facturado'
          }
        }
      ],
      attachmentsFields: [
        {
          name: 'file',
          title: 'Nombre'
        },
        {
          name: 'category',
          title: 'Categoría'
        },
        {
          name: 'instance',
          title: 'Instancia',
          formatter: (value) => {
            return value ? value.name : ''
          }
        },
        {
          name: 'last_modified',
          title: 'Última modificación',
          formatter: (value) => {
            const date = this.$moment(value)
            return date.isValid() ? date.format('DD-MM-YYYY') : ''
          }
        },
        {
          name: 'created_at',
          title: 'Cargado',
          formatter: (value) => {
            const date = this.$moment(value)
            return date.isValid() ? date.format('DD-MM-YYYY') : ''
          }
        },
        {
          name: 'action_attachment_details',
          title: 'Detalles'
        },
        {
          name: 'action_attachment',
          title: '',
          showOnVisibilityMenu: false
        }
      ],
      closeInstanceModal: false,
      changeInstanceModal: false,
      changeStatusModal: false,
      operationUploadingAttachmentCompleted: false,
      displayAgendaFilters: 0,
      expenseSelected: '',
      agendaEventSelected: '',
      modalOptions: {
        id: 'modalLogout',
        msg: '¿Seguro que desea borrar este expediente? Los cambios son irreversibles.',
        title: 'Borrando expediente',
        okVariant: 'danger',
        okTitle: 'Sí, estoy seguro',
        cancelTitle: 'No, cancelar',
        dialogClass: 'tms-modal',
        noCloseOnBackdrop: true,
        hideBackdrop: true,
        centered: true
      },
      statusDropdown: [
        {
          code: 3,
          label: 'Esperando Sentencia'
        },
        {
          code: 4,
          label: 'Fijar Audiencia'
        },
        {
          code: 5,
          label: 'Conociendose'
        }
      ],
      attachmentDetails: [],
      attachmentDetailModal: false
    }
  },
  watch: {
    agendaFilters: {
      immediate: true,
      deep: true,
      handler (value) {
        if (value.type) {
          this.$events.fire('refresh-table')
        }
        if (value.date_filter) {
          this.$events.fire('refresh-table')
        }
        if (value.date_custom) {
          this.$events.fire('refresh-table')
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateRecordRow: 'Record/update',
      deleteAttachment: 'Attachment/delete',
      delete: 'Record/delete',
      getCategoryAttachments: 'Attachment/getCategoryAttachments',
      fetchRecord: 'Record/fetchRecord',
      getInstancesDropdown: 'Instance/getInstancesDropdown',
      getCourtsDropdown: 'Court/getCourts'
    }),
    ...mapMutations({
      RECORD_TOGGLE_MODAL_UPDATE: 'Record/TOGGLE_MODAL_UPDATE',
      ATTACHMENT_TOGGLE_MODAL_CREATE: 'Attachment/TOGGLE_MODAL_CREATE',
      EXPENSE_TOGGLE_MODAL_CREATE: 'Expense/TOGGLE_MODAL_CREATE',
      EXPENSE_TOGGLE_MODAL_UPDATE: 'Expense/TOGGLE_MODAL_UPDATE',
      INVOICE_TOGGLE_MODAL_CREATE: 'Invoice/TOGGLE_MODAL_CREATE',
      TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL: 'Agenda/TOGGLE_CREATE_AGENDA_APPOINTMENT_MODAL',
      TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL',
      TOGGLE_CREATE_AGENDA_TASK_MODAL: 'Agenda/TOGGLE_CREATE_AGENDA_TASK_MODAL',
      TOGGLE_EDIT_AGENDA_TASK_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_TASK_MODAL',
      TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL: 'Agenda/TOGGLE_CREATE_AGENDA_AUDIENCE_MODAL',
      TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL'
    }),
    closeCase () {
      this.$swal.fire({
        title: '¿Desea cerrar el caso?',
        text: 'Ésta acción es permanente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#198ae3',
        cancelButtonColor: '#fe7c96',
        confirmButtonText: '¡Si, cerrar caso!',
        cancelButtonText: 'Cancelar'
      })
        .then((result) => {
          if (result.value) {
            const params = this.payload
            params.status = 2
            this.updateRecordRow(params)
              .then(() => {
                this.closeInstanceModal = false
                this.$swal('Operación Completada', '', 'success')
              })
          }
        })
    },
    closeChangeInstanceModal () {
      this.changeInstanceModal = false
    },
    closeChangeStatusModal () {
      this.changeStatusModal = false
    },
    changeInstance () {
      this.closeInstanceModal = false
      this.changeInstanceModal = true
    },
    changeStatus () {
      this.closeInstanceModal = false
      this.changeInstanceModal = false
      this.changeStatusModal = true
    },
    resetTables () {
      this.resetFilter()
      this.$events.fire('filter-reset')
    },
    resetFilter () {
      this.displayAgendaFilters = 0
      this.agendaFilters = {
        type: 4,
        date_filter: 0,
        date_custom: ''
      }
      this.$nextTick(() => this.$events.fire('refresh-table'))
    },
    deleteRecord () {
      this.$bvModal.msgBoxConfirm(this.modalOptions.msg, this.modalOptions)
        .then(value => {
          if (value) {
            this.delete(this.payload)
              .then(() => {
                this.$router.push({ name: 'records' })
              })
              .catch(error => {
                this.$swal({
                  title: 'Oops!',
                  text: error.response.data.message,
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateRecord () {
      this.updateRecordRow(this.payload)
        .then((response) => {
          this.payload = response.data
          this.closeChangeInstanceModal()
          this.$swal('Operación Completada', '', 'success')
        })
        .catch(error => {
          this.$swal({
            title: 'Oops!',
            text: error.response.data.message,
            type: 'error',
            confirmButtonText: 'Ok'
          })
        })
    }
  },
  computed: {
    ...mapGetters({
      instancesDropdown: 'Instance/instances_dropdown',
      courtsDropdown: 'Court/courts_dropdown'
    })
  },
  mounted () {
    this.getInstancesDropdown()
    this.getCourtsDropdown()
    this.$events.$on('refresh-table', (eventData, label) => {
      this.fetchRecord({
        id: this.id
      })
        .then(response => {
          this.payload = response.data
        })
    })
    this.$events.$on('table-item-edit', (eventData, label) => {
      if (label === 'agenda') {
        this.agendaEventSelected = eventData
        if (this.agendaEventSelected.type === 1) {
          this.TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL(true)
        }
        if (this.agendaEventSelected.type === 2) {
          this.TOGGLE_EDIT_AGENDA_TASK_MODAL(true)
        }
        if (this.agendaEventSelected.type === 3) {
          this.TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL(true)
        }
      }
      if (label === 'expense') {
        this.expenseSelected = eventData
        this.EXPENSE_TOGGLE_MODAL_UPDATE()
      }
    })
    this.$events.$on('table-item-attachment-delete', (eventData) => {
      this.deleteAttachment(eventData)
        .then(() => {
          this.resetTables()
        })
    })
    this.$events.$on('table-attachment-details-show', (eventData) => {
      this.attachmentDetails = eventData
      this.attachmentDetailModal = true
    })
    this.fetchRecord({
      id: this.id
    })
      .then(response => {
        this.payload = response.data
      })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    Datatable,
    RecordUpdate,
    ExpenseCreate,
    ExpenseUpdate,
    InvoiceCreate,
    File,
    EditAgendaAppointment,
    CreateAgendaAppointment,
    CreateAgendaTask,
    EditAgendaTask,
    CreateAgendaAudience,
    EditAgendaAudience,
    Timeline
  }
}
</script>
