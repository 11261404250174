<template>
    <div class="deal-timeline mt-4">
        <div v-if="loading" class="text-center">
            <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#2260be"><circle cx="15" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/></circle><circle cx="60" cy="15" r="9" fill-opacity=".3"><animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from=".5" to=".5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"/></circle><circle cx="105" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/></circle></svg>
        </div>
        <ul class="mb-0" v-else>
            <li v-for="(log, index) in logs" :key="index">
                <div class="timeline-icon" :class="color(log.type)">
                    <i class="fas fa-lg text-white" :class="icon(log.type)"></i>
                </div>
                <div class="timeline-item" v-if="log.type === 1">
                    <div class="item-body">
                        <div class="document-item">
                            <i class="fas fa-file-pdf fa-2x"></i>
                            <div class="document-meta">
                                <span><a>{{ log.details }}</a></span>
                                <span class="text-muted">{{ log.subtitle }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="timeline-item is-note" v-else-if="log.type === 3">
                    <div class="item-body">
                        <h3>Nota</h3>
                        {{ log.details }}<br>
                        <span class="is-thin text-muted">{{ log.subtitle }}</span>
                    </div>
                </div>
                <div class="timeline-item" v-else-if="log.type === 5">
                    <div class="item-body">
                        <strong>{{ log.action }}</strong><br>
                        <span class="is-thin text-muted">{{ log.subtitle }}</span>
                    </div>
                </div>
                <div class="timeline-item" v-else>
                    <div class="item-body">
                        <strong>{{ log.details }}</strong><br>
                        <span class="is-thin text-muted">
                            {{ log.subtitle }}<br>
                            <small>{{ log.by }}</small>
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: ['logs'],
  data: () => ({
    loading: true,
    types: [
      { name: 'Tarea', icon: 'mdi mdi-calendar-check', color: 'bg-info' },
      { name: 'Archivo', icon: 'mdi mdi-file-document', color: 'bg-success' },
      { name: 'Gasto', icon: 'mdi mdi-cash', color: 'bg-danger' },
      { name: 'Nota', icon: 'mdi mdi-note-text', color: 'bg-warning' },
      { name: 'Expediente', icon: 'mdi mdi-folder-multiple', color: 'bg-dark' },
      { name: 'Audiencia', icon: 'mdi mdi-calendar-clock', color: 'bg-info' }
    ]
  }),
  methods: {
    color (index) {
      return this.types[index].color
    },
    icon (index) {
      return this.types[index].icon
    }
  },
  created () {
    if (this.logs.length) {
      this.loading = false
    }
  }
}
</script>

<style scoped>
svg {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.deal-timeline ul {
  padding-left: 0;
}

.deal-timeline ul li {
  position: relative;
  display: flex;
  align-items: center;
}

.deal-timeline ul li:not(:last-child) {
  padding-bottom: 20px;
}

.deal-timeline ul li:last-child:after {
  height: 0vh;
}

.deal-timeline ul li:after {
  content: "";
  position: absolute;
  left: 19px;
  top: 50%;
  width: 1.6px;
  height: 100%;
  background: #d9d9d9;
}

.deal-timeline ul li .timeline-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
  font-size: 1.5rem;
}

.deal-timeline ul li .timeline-icon .material-icons {
  font-size: 18px;
  color: #000000;
}

.deal-timeline ul li .timeline-icon .sl {
  font-size: 16px;
  color: #000000;
}

.deal-timeline ul li .timeline-icon img {
  display: block;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 50%;
}

.deal-timeline ul li .timeline-item {
  position: relative;
  width: 100%;
  background: #fff;
}

.deal-timeline ul li .timeline-item:after,
.deal-timeline ul li .timeline-item:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.deal-timeline ul li .timeline-item:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 9px;
  margin-top: -9px;
}

.deal-timeline ul li .timeline-item.is-note {
  background: #fcfce5;
  border-color: #ffb14a;
}

.deal-timeline ul li .timeline-item.is-note:after {
  border-right-color: #fcfce5;
}

.deal-timeline ul li .timeline-item.is-note:before {
  border-right-color: #ffb14a;
}

.deal-timeline ul li .timeline-item .item-body {
  padding: 16px 20px;
}

.deal-timeline ul li .timeline-item .item-body h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: #000000;
}

.deal-timeline ul li .timeline-item .item-body .note-text {
  color: #000000;
  margin-right: 30px;
  margin-top: 6px;
}

.deal-timeline ul li .timeline-item .item-body .meta-list {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.deal-timeline ul li .timeline-item .item-body .meta-list .meta-list-item {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.deal-timeline ul li .timeline-item .item-body .meta-list .meta-list-item img {
  display: block;
  height: 26px;
  width: 26px;
  min-width: 26px;
  border-radius: 50%;
  margin-right: 8px;
}

.deal-timeline ul li .timeline-item .item-body .meta-list .meta-list-item i {
  font-size: 16px;
  margin-right: 3px;
  color: #000000;
}

.deal-timeline ul li .timeline-item .item-body .meta-list .meta-list-item span {
  display: block;
  font-weight: 500;
  color: #000000;
  font-size: 0.9rem;
}

.deal-timeline ul li .timeline-item .item-body .meta-list .meta-list-item span.is-thin {
  font-weight: 400;
}

.deal-timeline ul li .timeline-item .item-body .document-item {
  display: flex;
  align-items: center;
}

.deal-timeline ul li .timeline-item .item-body .document-item img {
  display: block;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 6px;
}

.deal-timeline ul li .timeline-item .item-body .document-item .document-meta {
  margin-left: 12px;
}

.deal-timeline ul li .timeline-item .item-body .document-item .document-meta span {
  display: block;
}

.deal-timeline ul li .timeline-item .item-body .document-item .document-meta span a {
  font-weight: 500;
}

.deal-timeline ul li .timeline-item .item-body .document-item .document-meta span:nth-child(2) {
  color: #000000;
  font-size: 0.9rem;
}

.deal-timeline ul li .timeline-item .item-footer {
  padding: 16px 20px;
  background: #e8e8e8;
  border-top: 1px solid #d9d9d9;
}

.deal-timeline ul li .timeline-item .item-footer p {
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000;
}
</style>
