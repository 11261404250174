<template>
  <b-modal v-model="ATTACHMENT_TOGGLE_MODAL_CREATE" ref="attachment-uploader" no-fade size="xl" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Adjuntar Archivos</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="form-group">
                <label>Categoría</label>
                <v-select
                  v-model="form.category"
                  :options="categories_attachments"
                  :reduce="field => field.value"
                  :create-option="tag => ({ label: tag, value: tag })"
                  label="label"
                  :taggable="true"
                  :push-tags="true"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>
                      Empiece a escribir para buscar.
                    </em>
                  </template>
                </v-select>
                <div v-if="errors.hasOwnProperty('category')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.category[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="form.category === 'Instancia'">
                <label>Instancia</label>
                <v-select
                  v-model="form.instance_id"
                  :options="instances_dropdown"
                  :reduce="field => field.code"
                  label="label"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>
                      Empiece a escribir para buscar.
                    </em>
                  </template>
                </v-select>
                <div v-if="errors.hasOwnProperty('category')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.category[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="form.category === 'Parte Contraria'">
                <label for="date">Fecha</label>
                <datetime v-model="form.date" :config="dateConfig" id="date" class="form-control"></datetime>
                <div v-if="errors.hasOwnProperty('date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.due_date[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="form.category === 'Parte Contraria'">
                <label for="number">No. Documento</label>
                <input type="text" v-model="form.number" class="form-control" id="number" />
                <div v-if="errors.hasOwnProperty('number')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.number[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="form.category === 'Parte Contraria'">
                <label for="delivered_by">Alguacíl</label>
                <input type="text" v-model="form.delivered_by" class="form-control" id="delivered_by" />
                <div v-if="errors.hasOwnProperty('delivered_by')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.delivered_by[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="form.category === 'Parte Contraria'">
                <label for="details">Detalles</label>
                <textarea v-model="form.details" class="form-control" id="details" rows="5" cols="5"></textarea>
                <div v-if="errors.hasOwnProperty('details')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.category[0]}}</small>
                </div>
              </div>
              <div class="form-group">
                <label>Archivos</label>
                <vue-dropzone ref="dropzone" id="dropzone" :options="dropzone" v-on:vdropzone-complete="completedEvent" v-on:vdropzone-sending="sendingEvent" v-on:vdropzone-files-added="filesAdded"  v-on:vdropzone-canceled="uploadCancelled"></vue-dropzone>
              </div>
              <div class="row justify-content-between">
                <div class="col-12 col-md-auto">
                  <button @click.prevent="reset" class="btn btn-outline-dark btn-md">Cancelar</button>
                </div>
                <div class="col-12 col-md-auto" v-if="attachmentQueueComplete">
                  <button @click.prevent="reset" class="btn btn-info btn-md">Continuar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Vue from 'vue'
import VueCookie from 'vue-cookie'

Vue.use(VueCookie)

const getTemplate = () => `<div class="dz-preview dz-file-preview">
<div class="dz-image">
  <div data-dz-thumbnail-bg></div>
</div>
<div class="dz-details">
  <div class="dz-filename"><span data-dz-name></span></div>
</div>
<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
<div class="dz-error-message"><span data-dz-errormessage></span></div>
<div class="dz-success-mark"><i class="fa fa-check"></i></div>
<div class="dz-error-mark"><i class="fa fa-close"></i></div>
</div>`

export default {
  name: 'FileUploaderModal',
  props: {
    payload: {
      type: Object,
      required: true
    },
    dateConfig: {
      altInput: true,
      enableTime: false,
      altFormat: 'F j, Y G:i K',
      dateFormat: 'Y-m-d H:i',
      time_24hr: false,
      locale: Spanish // locale for this instance only,
    }
  },
  data: () => {
    return {
      errors: [],
      attachmentQueueComplete: false,
      form: {
        category: '',
        instance_id: '',
        date: new Date().toISOString().split('T')[0],
        number: null,
        delivered_by: null,
        details: null
      },
      dropzone: {
        url: '/api/attachments',
        headers: {
          'X-XSRF-TOKEN': Vue.cookie.get('XSRF-TOKEN')
        },
        withCredentials: true,
        maxFilesize: 25, // MB
        maxFiles: 20,
        thumbnailWidth: 120, // px
        thumbnailHeight: 120,
        addRemoveLinks: true,
        dictDefaultMessage: '¡Arrastre los documentos aquí!',
        thumbnail: function (file, dataUrl) {
          if (file.previewElement) {
            file.previewElement.classList.remove('dz-file-preview')
            const images = file.previewElement.querySelectorAll('[data-dz-thumbnail]')
            for (let i = 0; i < images.length; i++) {
              const thumbnailElement = images[i]
              thumbnailElement.alt = file.name
              thumbnailElement.src = dataUrl
            }
            setTimeout(function () { file.previewElement.classList.add('dz-image-preview') }, 1)
          }
        },
        previewTemplate: getTemplate()
      }
    }
  },
  methods: {
    reset () {
      this.form = {
        category: '',
        instance_id: '',
        date: new Date().toISOString().split('T')[0],
        number: '',
        delivered_by: '',
        details: ''
      }
      this.attachmentQueueComplete = false
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_CREATE()
      this.getCategoryAttachments()
    },
    filesAdded () {
      this.$refs.dropzone.setOption('url', process.env.VUE_APP_BASE_URL + 'api/attachments')
    },
    uploadCancelled () {
      this.$refs.dropzone.setOption('url', process.env.VUE_APP_BASE_URL + 'api/attachments')
      window.location.replace('')
    },
    completedEvent () {
      this.attachmentQueueComplete = true
    },
    sendingEvent (file, xhr, formData) {
      formData.append('category', this.form.category)
      formData.append('date', this.form.date)
      formData.append('number', this.form.number)
      formData.append('delivered_by', this.form.delivered_by)
      formData.append('details', this.form.details)
      formData.append('record_id', this.payload.id)
      formData.append('instance_id', this.form.instance_id)
      formData.append('last_modified', new Date(file.lastModified).toISOString())
    },
    ...mapActions({
      getRecordAttachments: 'Attachment/getRecordAttachments',
      getCategoryAttachments: 'Attachment/getCategoryAttachments',
      getInstancesDropdown: 'Instance/getInstancesDropdown',
      fetchRecord: 'Record/fetchRecord'
    }),
    ...mapMutations({
      TOGGLE_MODAL_CREATE: 'Attachment/TOGGLE_MODAL_CREATE'
    })
  },
  computed: {
    ...mapGetters({
      ATTACHMENT_TOGGLE_MODAL_CREATE: 'Attachment/modalCreate',
      categories_attachments: 'Attachment/categories_attachments',
      instances_dropdown: 'Instance/instances_dropdown'
    })
  },
  mounted () {
    this.$events.$on('table-item-edit', (eventData, label) => {
      if (label === 'task') {
        this.taskSelected = eventData
        this.TASK_TOGGLE_MODAL_UPDATE()
      }
      if (label === 'audience') {
        if (eventData.aud_notes.length <= 0) {
          eventData.aud_notes = [
            { type: 1, details: '' },
            { type: 2, details: '' },
            { type: 3, details: '' }
          ]
        }
        this.audienceSelected = eventData
        this.AUDIENCE_TOGGLE_MODAL_UPDATE()
      }
    })
    this.getCategoryAttachments()
    this.getInstancesDropdown()
    this.getRecordAttachments({ record_id: this.payload.id })
  },
  components: {
    vueDropzone
  }
}
</script>
